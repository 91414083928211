@import '../../../../../styles/basics';

.bb-activism-cta-with-background {
  &:global(.bb) {
    @include bb-activism-spacing();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 8px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 8px;
  }

  &__content {
    border-radius: 8px;
    background: $blue-600;
    padding: 72px 16px;
  }

  &__top-group,
  &__text {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  &__top-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
  }

  &__title {
    font-size: 28px;
    font-weight: bold;
    color: $black;
    text-align: center;
  }

  a {
    margin: 0;
  };

  &__text {
    margin-top: 16px;
    text-align: center;

    p {
      color: $black;
    }

    a,
    a[href]:not([data-component^="button"]):hover {
      color: currentColor;
    }
  }

  &--variant-yellow {
    .bb-activism-cta-with-background__content {
      background: $yellow-600;
    }
  }

  &--variant-pink {
    .bb-activism-cta-with-background__content {
      background: $pink-800;
    }

    .bb-activism-cta-with-background__title,
    .bb-activism-cta-with-background__text p {
      color: $white;
    }
  }

  @include media-breakpoint-up(md) {
    &__top-group {
      flex-direction: row;
    }
  }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 36px;
    }

    &__text {
      font-size: 18px;
    }
  }
}
